import React, { Component } from 'react';
import axios from 'axios';
import './App.css';

class BonusTalepForm extends Component {
  state = {
    bonusKategorisi: '',
    bonuslar: [],
    secilenBonus: '',
    talepGonderiliyor: false,
    talepStatus: '',
    kullaniciAdi: '',
    DenemeAlmis: false,
    IPCakismasi: false,
    Dogrulama: true,
    DahaOnceYatirmis: false,
    KullaniciSonuc: ``,
    //kb result
    KBSonucMesaj: ``,
  };

  handleBonusKategorisiChange = (event) => {
    const selectedCategory = event.target.value;
    const bonuslar = this.getBonuslarByCategory(selectedCategory);
    this.setState({
      bonusKategorisi: selectedCategory,
      bonuslar,
      secilenBonus: '',
      talepStatus: '',
    });
  }

  handleSecilenBonusChange = (event) => {
    this.setState({ secilenBonus: event.target.value });
  }

  handleKullaniciAdiChange = (event) => {
    this.setState({ kullaniciAdi: event.target.value });
  }

  handleTalepGonder = () => {
    if (!this.state.bonusKategorisi || !this.state.kullaniciAdi || !this.state.secilenBonus) {
      this.setState({ talepStatus: 'Lütfen tüm alanları doldurun' });
      return;
    }
  
    const username = this.state.kullaniciAdi;
    const bonusType = this.getBonusTypeByBonusName(this.state.secilenBonus);
    this.setState({ talepGonderiliyor: true });
  
    let requestURL = '';
  
    // İstek URL'sini ve sonucunu kategorilere göre ayarla
    this.setState({ talepStatus: '' });
    if (bonusType === 'freespin' || bonusType === 'freebet') {
      requestURL = `https://api.antikbet.dev/AddTrialBonus?username=${username}&bonustype=${bonusType}`;
    } else if (bonusType === '1100') {
      requestURL = `https://api.antikbet.dev/AddDiscountBonus?username=${username}&bonustype=${bonusType}`;
    }
  
    axios
      .get(requestURL)
      .then((response) => {
        if (bonusType === 'freespin' || bonusType === 'freebet') {
          if (response.data.Sonuc === "Bonus eklendi.") {
            this.setState({ talepStatus: 'Onaylandı.' }, () => {
            this.setState({ talepGonderiliyor: false });
            });
          } else if (response.data.Sonuc === "Bonus eklenemez.") {
            this.setState({
              DenemeAlmis: response.data.DenemeAlmis,
              IPCakismasi: response.data.IPCakismasi,
              Dogrulama: response.data.Dogrulama,
              DahaOnceYatirmis: response.data.DahaOnceYatirmis,
              talepStatus: `Reddedildi.` // seems like the main host stopped lol what i do is sir, see 
            }, () => {
              this.setState({ talepGonderiliyor: false });
            });
            }else if(response.data.Sonuc === `Kullanıcı bulunamadı.`){
              this.setState({ 
                talepStatus: `Kullanıcı bulunamadı.`, 
              }, () => {
                  this.setState({ talepGonderiliyor: false });
                });
            }

        } else if (bonusType === '1100') {
          if (response.data.Result.BonusAdd === true) {
            this.setState({ talepStatus: 'Onaylandı.' }, () => {
              this.setState({ talepGonderiliyor: false });
            });

          } else if (response.data.Result.BonusRed === true) {
              this.setState({talepStatus: `Reddedildi.`, KBSonucMesaj: response.data.Result.Mesaj}, () => {
                this.setState({ talepGonderiliyor: false });
              });
          }
          
        }
        console.log(response.data.Sonuc)
      })
      .catch((error) => {
        this.setState({ talepGonderiliyor: false, talepStatus: 'Talep gönderme başarısız oldu' });
      });
  }

  getBonuslarByCategory(selectedCategory) {
    const bonusData = {
      denemebonusu: ['50 Deneme Freespin', '50₺ Deneme Freebet'],
      kayipbonusu: ['%30\'a Varan Anlık Kayıp Bonusu'],
      yatirimbonusu: [`%50 Yatırım Bonusu (Slot)`,'%25 Spor Bonusu',`%30 Casino Bonusu`,`%100 Risksiz Yatırım Bonusu`,`%15 Canlı Casino Bonusu`,`%150 Hoş geldin Spor Bonusu`,`%150 Hoş geldin Casino Bonusu`],
      ozelbonuslar: [`Test`]
      // Diğer kategoriler ve bonuslar...
    };
    return bonusData[selectedCategory] || [];
  }

  getBonusTypeByBonusName(bonusName) {
    const bonusTypes = {
      '50 Deneme Freespin': 'freespin',
      '50₺ Deneme Freebet': 'freebet',
      '%30\'a Varan Anlık Kayıp Bonusu': '1100', //kayıp bonusu
      // Diğer bonuslar ve türleri...
    };
    return bonusTypes[bonusName] || '';
  }

  render() {
    return (
      <div className="container">
        <div className="form">
          <h2>Bonus Talep Formu</h2>
          <h5 style={{ color: 'grey' }}>Yatırım bonuslarımız geçici bir süre için Canlı Destek hattımızdan eklenmektedir.</h5>
          {this.state.talepStatus === 'Lütfen tüm alanları doldurun' && (
            <div className="notification" style={{ backgroundColor: '#FF5733', color: '#fff' }}>
              Lütfen tüm alanları doldurunuz.
            </div>
          )}
           {this.state.talepStatus === `Kullanıcı bulunamadı.` && this.state.bonusKategorisi === 'denemebonusu' && (
            <div className="notification" style={{ backgroundColor: '#FF5733', color: '#fff' }}>
              {'Kullanıcı bulunamadı.'}
            </div>
          )}
          {this.state.talepStatus === `Reddedildi.` && this.state.bonusKategorisi === 'denemebonusu' && (
            <div className="notification" style={{ backgroundColor: '#FF5733', color: '#fff' }}>
              {this.state.DenemeAlmis ? 'Daha önce deneme bonusundan faydalandığınız için tekrar deneme bonusu eklenememektedir.' :
                  this.state.IPCakismasi ? 'İP çakışmanız mevcuttur, İP çakışması olan kullanıcılar deneme bonusundan faydalanamamaktadırlar.' :
                    !this.state.Dogrulama ? 'Hesabınız doğrulanmamıştır, canlı destek hattımıza bağlanıp hesabınızı doğruladıktan sonra tekrar talep verebilirsiniz.' :
                      this.state.DahaOnceYatirmis ? 'Deneme bonusundan daha önce yatırım yapmamış olan üyeler faydalanabilmektedirler.' : ''
              }
            </div>
          )}

          {this.state.talepStatus === `Reddedildi.` && this.state.bonusKategorisi === 'kayipbonusu' && (
            <div className="notification" style={{ backgroundColor: '#FF5733', color: '#fff' }}>
              {`${this.state.KBSonucMesaj ? `${this.state.KBSonucMesaj}` : ''}
              `}
            </div>
          )}

          {this.state.talepStatus === 'Onaylandı.' && (
            <div className="notification" style={{ backgroundColor: '#12752d', color: '#white' }}>
              Bonusunuz eklenmiştir.
            </div>
          )}
          <div className="form-group">
            <label htmlFor="bonusKategorisi" className="label">Bonus Kategorisi:</label>
            <select
              id="bonusKategorisi"
              className="input"
              value={this.state.bonusKategorisi}
              onChange={this.handleBonusKategorisiChange}
            >
              <option value="">Seçiniz</option>
              <option value="denemebonusu">Deneme Bonusları</option>
              <option value="kayipbonusu">Kayıp Bonusu</option>
              {/* Diğer kategoriler */}
              {/* 
              
              <option value="yatirimbonusu">Yatırım Bonusları</option>
              <option value="ozelbonuslar">Özel Bonuslar</option> */}
            </select>
          </div>
          {this.state.bonuslar.length > 0 && (
            <div className="form-group">
              <label htmlFor="secilenBonus" className="label">Bonus Seç:</label>
              <select
                id="secilenBonus"
                className="input"
                value={this.state.secilenBonus}
                onChange={this.handleSecilenBonusChange}
              >
                <option value="">Seçiniz</option>
                {this.state.bonuslar.map((bonus, index) => (
                  <option key={index} value={bonus}>
                    {bonus}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="kullaniciAdi" className="label">Kullanıcı Adı:</label>
            <input
              id="kullaniciAdi"
              className="input"
              type="text"
              value={this.state.kullaniciAdi}
              onChange={this.handleKullaniciAdiChange}
            />
          </div>
          <div className="form-group">
            {this.state.talepGonderiliyor ? (
              <button className="button" disabled>Gönderiliyor...</button>
            ) : (
              <div>
                <button className="button" onClick={this.handleTalepGonder}>Talebi Gönder</button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BonusTalepForm;